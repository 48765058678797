<template>
  <div style="padding-right: 3.4em">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showsnackbar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{msg}}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color:white;">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar> 
    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title>Edit Order : {{ invoice.orderID }}</v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <!-- <v-row class="ml-1" dense style="width:100%!important">
                    <span style="font-size: 13px">Billing Address</span></v-row
                  > -->

                  <v-row dense>
                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        color="black"
                        label="Bill to company"
                        outlined
                        dense
                        v-model="invoice.billToCompany"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        color="black"
                        label="Address"
                        outlined
                        dense
                        v-model="invoice.billToAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        color="black"
                        label="City"
                        outlined
                        dense
                        v-model="invoice.billToCity"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1" style="padding-bottom:0px!important">
                      <!-- <v-text-field
                           
                        color="black"
                        label="State"
                           
                        outlined
                        dense
                        v-model="companyName"
                      ></v-text-field> :items="items" -->
                      <v-select
                        ref="state"
                        color="black"
                        label="State"
                        outlined
                        dense
                        v-model="invoice.billToStateId"
                        :items="states"
                        item-text="alphaCode"
                        item-value="_id"
                      ></v-select>
                    </v-col>
                    <v-col md="2" style="padding-bottom:0px!important">
                      <v-text-field
                        color="black"
                        label="Zip"
                        outlined
                        dense
                        v-model="invoice.billToZip"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row class="ml-1" dense style="width:100%!important">
                    <span style="font-size: 13px">Shipping Address</span></v-row
                  > -->

                  <v-row dense>
                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        color="black"
                        label="Ship To Company"
                        outlined
                        dense
                        v-model="invoice.shipToCompany"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        color="black"
                        label="Address"
                        outlined
                        dense
                        v-model="invoice.shipToAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        color="black"
                        label="City"
                        outlined
                        dense
                        v-model="invoice.shipToCity"
                      ></v-text-field>
                    </v-col>

                    <v-col md="1" style="padding-bottom:0px!important">
                      <v-select
                        :items="states"
                        ref="state"
                        color="black"
                        label="State"
                        outlined
                        dense
                        v-model="invoice.shipToStateId"
                        item-text="alphaCode"
                        item-value="_id"
                      ></v-select>
                    </v-col>
                    <v-col md="2" style="padding-bottom:0px!important">
                      <v-text-field
                        color="black"
                        label="Zip"
                        outlined
                        dense
                        v-model="invoice.shipToZip"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row>
                    <v-col cols="12" sm="3" md="3" style="padding-bottom:0px!important">
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="orderDate"
                            label="Order Date"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="invoice.orderDate"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" style="padding-bottom:0px!important">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="shippingDate"
                            label="Shipping Date"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="invoice.shippingDate"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        color="black"
                        label="Customer P.O"
                        outlined
                        dense
                        v-model="invoice.customerPO"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        v-if="invoice.lastOrderId"
                        color="black"
                        label="Order No.(Last Order)"
                        outlined
                        dense
                        v-model="invoice.lastOrderId.orderID"
                      ></v-text-field>
                       <v-text-field
                        v-else
                        color="black"
                        label="Order No.(Last Order)"
                        outlined
                        dense
                        v-model="invoice.lastOrderId"
                      ></v-text-field>
                    </v-col>
                  
                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        color="black"
                        label="Phone"
                        outlined
                        dense
                        v-model="phoneno"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        color="black"
                        label="Email"
                        outlined
                        dense
                        v-model="invoice.email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    <v-col md="3" style="padding-bottom:0px!important">
                    
                      <v-text-field 
                        label="Ship Via"
                        v-model="shipMethodId1"
                        
                        dense
                        outlined
                      ></v-text-field>
                      <!-- <v-text-field 
                        label="Ship Via"
                        v-model="invoice.shipMethodId"
                        item-text="name"
                        item-value="_id"
                        dense
                        outlined
                      ></v-text-field> -->
                    </v-col>
                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        
                        ref="terms"
                        color="black"
                        label="Terms"
                        outlined
                       
                        
                        v-model="invoice.terms"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        v-if="invoice.customerId != null"
                        color="black"
                        label="Agent"
                        outlined
                        dense
                        disabled
                        v-model="invoice.customerId.agentCode"
                      ></v-text-field>
                    </v-col>

                    <v-col md="3" style="padding-bottom:0px!important">
                      <v-text-field
                        v-if="invoice.customerId != null"
                        color="black"
                        label="Customer No"
                        outlined
                        dense
                        :rules="customer"
                        v-model="invoice.customerId.customerID"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense style="width:100%!important">
                    <v-col md="6" class="text-left"
                      ><p class="pt-4">Orders</p></v-col>
                   <v-col v-if="productinfo.length == 0" md="6" class="text-right pt-0"
                      ><v-btn fab small depressed color="primary" @click="update">
                        <v-icon dark  > mdi-plus </v-icon>
                      </v-btn></v-col >
                    </v-row>
 
                  <v-row dense v-for="(item, i) in productinfo" :key="i">
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Order Qty"
                        outlined
                        dense
                        v-model="item.quantity"
                        v-on:input="amtComputation(item)"
                        v-on:change="prdUpdate(item)"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Ship Qty"
                        outlined
                        dense
                        v-model="item.shipQuantity"
                        v-on:input="amtComputation(item)"
                        v-on:change="prdUpdate(item)"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        color="black"
                        label="Description"
                        outlined
                        dense
                        :rules="descriptionrule"
                        v-model="item.description"
                        v-on:change="prdUpdate(item)"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Unit Price"
                        outlined
                        dense
                        v-model="item.unitPrice"
                        v-on:input="amtComputation(item)"
                        v-on:change="prdUpdate(item)"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Um"
                        outlined
                        dense
                        v-model="item.um"
                        v-on:change="prdUpdate(item)"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1" class="text-right;">
                      <v-checkbox
                        v-model="item.isTaxable"
                        label="Tx"
                        v-on:change="amtComputation(item), prdUpdate(item)"
                      ></v-checkbox>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Amount"
                        outlined
                        dense
                        v-model="item.amount"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col md="1">
                      <v-file-input
                        hide-input
                        
                      ></v-file-input>
                    </v-col> -->
                    <v-col md="1">
                      <v-btn
                        
                        class="mx-2"
                        depressed
                        fab
                        dark
                        small
                        color="error"
                        @click="removee(i, item)"
                      >
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col md="1" >
                      <v-btn v-if="i == (productinfo.length-1)" fab depressed small color="primary" @click="update">
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                   
                  <v-row dense class="justify-end">
                     <v-col md="2">
                      <v-text-field
                        color="black"
                        label="Sales Tax"
                        outlined
                        dense
                        disabled
                        v-model="salesTax"
                      ></v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                        color="black"
                        label="Est.Total"
                        outlined
                        dense
                        disabled
                        v-model="estTotal"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title>
                  <v-row dense>
                    <v-col md="12">
                      <v-file-input
                        v-model="atfiles"
                        @change="getFiles"
                        color="black"
                        counter
                        dense
                        label="File input"
                        multiple
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip v-if="index < 2" dark label small>
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
                 <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 14px"><b>Notes</b></span>
                  </v-row>

                  <v-row dense>
                    <v-col md="2">
                      <v-row dense>
                        <v-col md="12">
                          <v-text-field
                            ref="Agent"
                            color="black"
                            label="Agent"
                            dense
                            disabled
                            outlined
                            v-model="agentCode"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="10">
                      <v-row dense v-for="(item, i) in notes" :key="i">
                        <v-col md="8">
                          <v-text-field
                            ref="Notes"
                            color="black"
                            label="Note"
                            dense
                            outlined
                            v-model="item.note"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col md="2">
                         <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.create_date"
                            label=" Date"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="item.create_date "
                          @input="menu3 = false"
                        ></v-date-picker>
                      </v-menu>
                        </v-col>
                        <v-col md="1">
                          <v-btn
                            v-if="i > 0"
                            class="mx-2"
                            depressed
                            fab
                            dark
                            small
                            color="error"
                            @click="notesremovee(i)"
                          >
                            <v-icon dark> mdi-minus </v-icon>
                          </v-btn>

                        </v-col>
                        <v-col md="1">
                          <v-btn
                            v-if="i == notes.length - 1"
                            fab
                            depressed
                            small
                            color="primary"
                            @click="notesupdate"
                          >
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
          <v-card class="mb-4">
            <v-layout pb-5 wrap justify-center>
              <v-flex xs12 md12 pt-4 text-center>
                <v-btn
                  py-4
                  dark
                  color="primary"
                  depressed
                  class="text-capitalize"
                  :ripple="false"
                  @click="addValidate"
                >
                  Submit
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
      <v-navigation-drawer
      style="position: fixed; padding-top: 5%"
      right
      width="55"
    >
      <v-list nav dense>
        <v-list-item link title="Pdf">
          <v-list-item-icon>
            <v-icon color="primary" @click="Print = true">mdi-file-pdf-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
          <v-dialog v-model="Print" max-width="500">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Print
              </v-card-title>

              <v-card-text>
                <form ref="bthPrint" class="pt-4">
                  <v-radio-group v-model="printType" row>
                    <v-radio label="Order Copy" value="copy"></v-radio>
                    <v-radio label="Packing List" value="packinglist"></v-radio>
                    <v-radio label="Blank P/L" value="blankpackinglist"></v-radio>
                  </v-radio-group>
                </form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error" @click="Print = false"> Cancel </v-btn>
                <v-btn color="primary" class="mr-4" @click="Printdata">
                  View Print
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>
        <v-list-item link title="Batch Print">
          <v-list-item-icon>
            <v-icon color="primary" @click="emailDialog = true"
              >mdi-gmail</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
          <v-dialog v-model="emailDialog" max-width="800">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                ABB Labels
              </v-card-title>

              <v-card-text>
                <form ref="bthPrint" class="pt-4">
                  <v-text-field
                    v-model="sendfrom"
                    label="Send From"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="sendto"
                    label="Send To"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="subject"
                    label="Subject"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <vue-editor
                    v-model="message"
                    placeholder="Message"
                    color="#26af82"
                    :editorToolbar="customToolbar"
                  ></vue-editor>
                </form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error" @click="emailDialog = false">
                  Cancel
                </v-btn>
                <v-btn color="primary" class="mr-4" @click="sendMail">
                  Send
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>
        <!-- <v-list-item
          link
          :to="'/editOrder/' + $route.params.id"
          title="Edit Order"
        >
          <v-list-item-icon>
            <v-icon color="primary">mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item> -->
        <v-list-item link title="Attachment">
          <v-list-item-icon v-if="files.length > 0">
            <a
              target="_blank"
              :href="'https://www.abblabels.ml/file/get/' + files[0]"
              class="downlink"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </a>
          </v-list-item-icon>
          <v-list-item-icon v-else>
            <v-icon color="grey" class="mr-2"> mdi-eye-off </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item link title="Batch Print">
          <v-list-item-icon>
            <v-icon color="primary" @click="batchPrint = true"
              >mdi-printer</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
          <v-dialog v-model="batchPrint" max-width="300">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Batch Print
              </v-card-title>

              <v-card-text>
                <v-radio-group v-model="printType1" row>
                  <v-radio label="Print as PDF" value="pdf"></v-radio>
                  <v-radio
                    label="Print as Plain text"
                    value="plaintext"
                  ></v-radio>
                </v-radio-group>
                <form ref="bthPrint" class="pt-4">
                  <v-text-field
                    v-model="start"
                    label="Start with #."
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="until"
                    label="Until #."
                    dense
                    outlined
                    required
                  ></v-text-field>
                </form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error" @click="batchPrint = false">
                  Cancel
                </v-btn>
                <v-btn color="primary" class="mr-4" @click="batchPrintdata">
                  Print
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item> -->
        <v-list-item
          :to="{ name: 'addInvoice', params: { orderid: $route.params.id } }"
          title="Add Invoice"
        >
          <v-list-item-icon>
            <v-icon color="primary">mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";
 import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      notes:[
        {  note:"",
          create_date: new Date().toISOString().substr(0, 10),
        }
      ],
      menu3:false,
      printType1:"pdf",
      start: null,
      until: null,
      batchPrint:false,
      emailDialog:false,
       Print: false,
        printType:"copy",
         sendfrom: null,
      sendto: null,
      message: null,
      subject: null,
       customToolbar: [[]],
        shipMethodId1:null,
      invoice: {
        billToCompany: null,
        billToStateId: null,
        billToCity: null,
        billToZip: null,
        shipToAddress: null,
        shipToStateId: null,
        shipToCity: null,
        shipToZip: null,
        terms: null,
        email: null,
        phone: null,
        customerPO: null,
        customerId: null,
        shipMethodId: null,
        abbOrderno: null,
        orderDate: new Date().toISOString().substr(0, 10),
        shippingDate: "",
        orderProducts: [],
        deleteProducts:[],
        lastOrderId: null,
      },
      orderData:[],
      files:[],
      updateProducts: [],
      states: [],
      terms: [],
      shipvia: [],
      menu1: false,
      modal: false,
      menu2: false,
      dialog: false,
      dialogDelete: false,
      salesTax: null,
      taxAmount: null,
      estTotal: null,
      
      productinfo: [
        {
          quantity: "",
          shipQuantity: "",
          description: "",
          unitPrice: "",
          um: "",
          amount: "",
          isTaxable: false,
          sr_no: null,
        },
      ],
      // notes: [
      //  {
      //    note:" ",
      //   create_date:''
        
      //  }
      // ],
      deleteProducts:[],
      sn_value: 0,
      
      qtyrules: [
        (v) => !!v || "Required",
        // v =>
        //   (!!v && v.length <= 10) ||
        //   "Phone number must be less than 10 characters",
        (v) => !isNaN(parseFloat(v)) || "Number is Expected",
      ],
      shipqtyrules: [(v) => !isNaN(parseFloat(v)) || "Number is Expected"],
      untPrice: [
        (v) => !!v || "Required",
        (v) => !isNaN(parseFloat(v)) || "Number is Expected",
        (v) => v >= 0,
      ],
      descriptionrule: [(v) => !!v || "required"],
      customer: [(v) => !!v || "required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      atfiles:[],
      attachments:[],
      agentCode: localStorage.getItem("agentCode"),
      attachmentsformData: new FormData(),
      showsnackbar: false,
      msg:'',
      timeout:2000,
      appLoading:false,
      phoneno:null,
    };
  },

  beforeMount() {
    this.stateList();
    this.termList();
    this.shipList();
    this.getTax();
    this.getOrder();
  },
  
   computed: {
       orderDate () {
      return this.formatDate(this.invoice.orderDate)
    },
      shippingDate () {
         
      return this.formatDate(this.invoice.shippingDate)
    },
      
  },

watch:{
    phoneno() {
      //  console.log("here")
      var str = this.phoneno;
      var ind;
      if (str.length == 3) {
        ind = str.substring(0, 3);
        this.phoneno = ind + "-";
      }
      if (str.length == 8) {
        ind = str.substring(0, 7);
        this.phoneno = ind + "-";
      }
    },
    // notes() {
    //   return this.formatDate(this.notes.create_date)
      
    // }, 
  },
 


  methods: {
    //  batchPrintdata() {
    //   this.batchPrint = false;
    //   window.open(
    //     "http://13.127.171.21/pdf/generate/Order_Batch_Details/?start=" +
    //       this.start +
    //       "&end=" +
    //       this.until +
    //       "&printType=" +
    //       this.printType1
    //   );
    // },
     
    sendMail() {
      this.emailDialog = false;

      this.sendTo = this.sendto.split(",");
      axios({
        method: "POST",
        url: "/send/email",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // status:"active",
          emailType: "order",
          id: this.$route.params.id,
          emailTo: this.sendTo,
          emailFrom: this.sendfrom,
          subject: this.subject,
          message: this.message,
        },
      })
        .then((response) => {
          if(response.data.status){
          this.appLoading = false;
          // console.log(response.data.msg);
          this.message = "";
          this.showsnackbar = true;
          this.msg = "Email sent successfully!";
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    Printdata() {
      this.Print = false;

      window.open(
        "https://www.abblabels.ml/pdf/generate/Order_Details/?orderId=" +
          this.$route.params.id+"&printType="+this.printType,
        "_blank"
      );
    },
      notesupdate() {
      this.notes.push({
        note: "",
      });
    },
    notesremovee(index) {
      this.notes.splice(index, 1);
    },
    getTax() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/tax/view/" + "5f7e94279eeed32c3477263d",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.taxAmount = response.data.data.taxAmount;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getOrder() {
      // this.appLoading = true;
      this.updateProducts=[];
      axios({
        method: "POST",
        url: "/order/view/" + this.$route.params.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;

          if (response.data.status) {
            this.invoice = response.data.data;
            // console.log(this.invoice.shipMethodId,"ship method")
            if( response.data.data.shipMethodId)
            {
              this.shipMethodId1 = response.data.data.shipMethodId.name;
              console.log("this",this.shipMethodId1)
            }
            this.phoneno = response.data.data.phone;
            this.estTotal = this.invoice.totalAmount.toFixed(2);
            this.salesTax = this.invoice.tax.toFixed(2);
            this.productinfo = this.invoice.products;
             if(this.invoice.attachments.length>0)
            this.files = this.invoice.attachments;
            
            if (this.productinfo.length < 1) {
              this.productinfo = [
                {
                  quantity: "",
                  shipQuantity: "",
                  description: "",
                  unitPrice: "",
                  um: "",
                  amount: "",
                  isTaxable: "",
                },
              ];
            }
            if(this.invoice.orderDate)
            this.invoice.orderDate = this.ChangeFormateDate(
             this. invoice.orderDate
            );
            
             if(this.invoice.shippingDate)
            this.invoice.shippingDate = this.ChangeFormateDate(
            this.invoice.shippingDate
            );
            if (this.invoice.notes.length>0)
            {
             this.notes = this.invoice.notes;
             for(let i=0;i< this.notes.length;i++)
             {
            //  this.notes.create_date = this.ChangeFormateDate(this.invoice.notes.create_date)
            this.notes[i].create_date = this.formatDate(this.notes[i].create_date.slice(0,10))
            }
            }
            else{
              this.notes=[{note:""}];
            }
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    stateList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/states",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.states = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    termList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/term/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.terms = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    shipList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/shippingMethod/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.shipvia = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    update() {
      this.sn_value = this.sn_value + 1;
      this.productinfo.push({
        quantity: "",
        shipQuantity: "",
        description: "",
        unitPrice: "",
        um: "",
        amount: "",
        isTaxable: false,
        sr_no: this.sn_value,
      });
      
    },
    removee(index, item) {
      this.productinfo.splice(index, 1);
      for (var r = 0; r < this.updateProducts.length; r++) {
        if (this.updateProducts[r].sr_no == item.sr_no) {
          this.updateProducts.splice(r, 1);
        }
      }
     if(item._id)
     this.deleteProducts.push(item._id)

      var estimate = 0;
      var tax = 0;
     
      for (var j = 0; j < this.productinfo.length; j++) {
        estimate = estimate + this.productinfo[j].amount;
        if (this.productinfo[j].isTaxable)
          tax = tax + this.productinfo[j].amount * (this.taxAmount / 100);
      }
      this.estTotal = (estimate + tax).toFixed(2);
      this.salesTax = tax.toFixed(2);
     
    },

    addValidate() {
      if (this.$refs.form.validate()) this.editOrder();
    },

    amtComputation(item) {
      var estimate = 0;
      var tax = 0;
      if (item.shipQuantity != 0) {
        item.amount = item.unitPrice * item.shipQuantity;
      } else item.amount = item.unitPrice * item.quantity;

      for (var j = 0; j < this.productinfo.length; j++) {
        estimate = estimate + this.productinfo[j].amount;
        if (this.productinfo[j].isTaxable)
          tax = tax + this.productinfo[j].amount * (this.taxAmount / 100);
      }
      this.estTotal = estimate + tax;
      this.salesTax = tax.toFixed(2);
    },
    prdUpdate(item) {
      if (item._id) {
        if (this.updateProducts.length == 0) this.updateProducts.push(item);
        else {
          for (var i = 0; i < this.updateProducts.length; i++) {
            if (this.updateProducts[i]._id == item._id) {
              this.updateProducts.splice(i, 1);
            }
          }
          this.updateProducts.push(item);
        }
      } else {
        if (this.updateProducts.length == 0) this.updateProducts.push(item);
        else {
          for (var k = 0; k < this.updateProducts.length; k++) {
            if (this.updateProducts[k].sr_no == item.sr_no) {
              this.updateProducts.splice(k, 1);
            }
          }
          this.updateProducts.push(item);
        }
      }
    },
    editOrder() {
      this.appLoading = true;
      this.invoice.phone = this.phoneno;
      this.invoice.orderProducts = this.updateProducts;
      this.invoice.deleteProducts = this.deleteProducts;
      axios({
        method: "POST",
        url: "/order/edit/" + this.$route.params.id,
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.invoice,
      })
        .then((response) => {
          if (response.status) {
            this.appLoading = false;
             if (this.attachments.length>0) {
              for (var i = 0; i < this.attachments.length; i++) {
                this.attachmentsformData.append(
                  "attachments",
                  this.attachments[i]
                );
              }
              this.uploadattachments();
            }
            if(this.notes.length>0)
            this.editNotes();

            this.getOrder();

             this.$notify({
              group: "appNotify",
              title: "ABB Labels",
              text: "Order Edited!",
            });
             this.$router.push("/Order/" + this.$route.params.id);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    formreset() {
      this.$refs.form.reset();
    },
      editNotes(){
       axios({
        method: "POST",
        url: "/note/add",
        data: {
         orderId:this.$route.params.id,
         notes:this.notes
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            
           this.appLoading = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getFiles() {
      var selectedFiles = this.atfiles[0];

      if (this.atfiles) {
        this.msg = null;
        this.attachments.push(selectedFiles);
      }
      console.log(this.attachments)
    },
    
     uploadattachments() {
     
      axios({
        method: "POST",
        url: "/order/upload/attachments/" + this.$route.params.id,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },

        data: this.attachmentsformData,
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          //this.appLoading = true;
          // vm.uploadMsg = "Uploading " + percentCompleted + "%";
          if (percentCompleted == 100) {
            // this.appLoading = false;
          }
        },
      })
        .then((response) => {
          if (response.status) {
            this.appLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    ChangeFormateDate(oldDate) {
      if(oldDate)
      var newdateFormat = oldDate.toString().split("T");
      return newdateFormat[0];
    },
     formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      formatDate1 (dates) {
         if (!dates) return null
          const val  = new Date(dates)
          return val
      },
  
  },
};
</script>

<style >
.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important;
  font-size: 12px !important;
  font-weight: bold;
  color:black!important;
}
.v-text-field input {
  color: black !important;
}
.v-card__title {
  line-height: 1rem !important;
}
.titlestyle{
  padding-bottom: 0px!important;
}
</style>



